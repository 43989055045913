:root {
  --max-vw: 1440;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .75;
  --min-fs-rem: calc(var(--min-fs) * 1rem);
  --max-fs-rem: calc(var(--max-fs) * 1rem);
  --min-vw-rem: calc((var(--min-vw) / 10) * 1rem);
  --slope: calc((var(--max-fs)  - var(--min-fs)) * (100vw - var(--min-vw-rem)) / ((var(--max-vw) / 10)  - (var(--min-vw) / 10)));
  --font-size-clamp: clamp(var(--min-fs-rem), calc(var(--min-fs-rem)  + var(--slope)), var(--max-fs-rem));
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;
}

html {
  font-feature-settings: "kern" 1, "liga" 0;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 10px;
}

body {
  font-family: var(--font-family-base, "Inter");
  font-size: var(--font-size-clamp);
  line-height: var(--line-height-base);
  font-weight: 400;
}

a {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

.display-large {
  font-size: 5.7em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.08em;
}

.display-medium {
  font-size: 4.5em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.display-small {
  font-size: 3.6em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.headline-large {
  font-size: 3.2em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-medium {
  font-size: 2.8em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-small {
  font-size: 2.4em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.05em;
}

.title-large {
  font-size: 2.2em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.04em;
}

.title-medium {
  font-size: 1.6rem;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.03em;
  font-weight: 600;
}

.title-small {
  font-size: 1.4rem;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  font-weight: 600;
}

.label-large {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
}

.label-medium {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.label-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.body-large {
  text-wrap: balance;
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.body-medium {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
}

.body-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

ul, ol {
  padding-left: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  list-style-position: outside;
}

:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

a, button {
  cursor: revert;
}

ol, ul, menu, summary {
  list-style: none;
}

img {
  max-block-size: 100%;
  max-inline-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

:where(pre) {
  all: revert;
  box-sizing: border-box;
}

::placeholder {
  color: unset;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
  box-sizing: border-box;
}

::-webkit-details-marker {
  display: none;
}

:root {
  --z-index-header: 1;
  --c-white: #fff;
  --c-black: #000;
  --c-golden-sand: #e3b454;
  --c-pale-yellow: #fbffa4;
  --c-amber: #ffb700;
  --c-vivid-red: #e32245;
  --v-offset-sm: min(50px, 3vmax);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);
  --max-vw: 1600;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .6;
  --font-family-base: "Merriweather", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;
  --c-scrollbar-track: var(--c-black);
  --c-scrollbar-thumb: var(--c-vivid-red);
  --c-scrollbar-hover: var(--c-vivid-red);
}

@media (width >= 992px) {
  :root {
    --v-offset-sm: min(40px, 2vmax);
    --v-offset-md: min(80px, 4vmax);
  }
}

html, body {
  position: relative;
}

body {
  background-color: var(--c-black);
  color: var(--c-white);
  width: 100vw;
  overflow: hidden auto;
}

@supports (min-height: 100dvh) {
  body {
    min-height: 100dvh;
  }
}

@supports not (min-height: 100dvh) {
  body {
    min-height: 100vh;
  }
}

body::-webkit-scrollbar {
  opacity: 0;
  width: 12px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--c-scrollbar-thumb, var(--c-canary-yellow));
  border: 4px solid var(--c-scrollbar-track, var(--c-blue));
  border-radius: 2px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--c-scrollbar-hover, var(--c-text-greenish-yellow));
}

body::-webkit-scrollbar-track {
  background-color: var(--c-scrollbar-track, var(--c-blue));
}

.display-small {
  text-align: center;
  margin: var(--v-offset-sm) 0;
  width: unset;
  color: var(--c-amber);
  max-inline-size: none;
  font-size: 5em;
  font-weight: 700;
}

.headline-large {
  text-align: center;
  color: var(--c-amber);
  width: min(100% - 30px, 960px);
  max-inline-size: none;
  font-size: 3.8em;
  font-weight: 700;
}

.headline-medium {
  text-align: center;
  color: var(--c-amber);
  width: min(100% - 30px, 960px);
  max-inline-size: none;
  font-size: 4.8em;
  font-weight: 700;
}

.headline-small {
  text-align: center;
  max-inline-size: none;
  font-size: 2.5em;
  font-weight: 300;
  line-height: 1.35;
}

.title-large {
  font-size: 3em;
  font-weight: 600;
}

.landing {
  padding-bottom: var(--v-offset-md);
  place-items: start center;
  width: 100%;
  display: grid;
}

.svg-icon--logo-volta {
  aspect-ratio: 1;
  fill: #a9a9a9;
  width: clamp(160px, 22vmin, 300px);
}

.button {
  letter-spacing: .5px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  appearance: none;
  text-transform: uppercase;
  color: var(--c-white);
  background-size: 200% 100%;
  border: none;
  border-radius: 1px;
  place-content: center;
  width: fit-content;
  height: 56px;
  margin: 0;
  padding: 0 46px;
  font-size: 18px;
  font-weight: 900;
  text-decoration: none;
  transition: all .525s;
  display: grid;
}

@media (width >= 1200px) {
  .button {
    font-size: 20px;
  }
}

@media (hover: hover) and (pointer: fine) {
  .button:hover {
    background-position: 100% 100%;
  }
}

.button--vivid-red {
  position: relative;
  overflow: hidden;
}

.button--vivid-red:before, .button--vivid-red:after {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0;
}

.button--vivid-red:before {
  background-color: var(--c-vivid-red);
}

@media (hover: hover) and (pointer: fine) {
  .button--vivid-red {
    transition: all .275s ease-in;
  }

  .button--vivid-red:after {
    border-right: 56px solid #0000;
    border-bottom: 56px solid var(--c-white);
    width: calc(100% + 56px);
    transition: all .325s ease-in-out;
    transform: translateX(-100%);
  }

  .button--vivid-red:hover {
    color: var(--c-vivid-red);
  }

  .button--vivid-red:hover:after {
    transform: translateX(0);
  }
}

.button--sky-blue {
  color: var(--c-white);
  box-shadow: 0 0 0 2px inset var(--c-white);
  background-color: #0000;
  transition: all .325s ease-in-out;
}

@media (hover: hover) and (pointer: fine) {
  .button--sky-blue:hover {
    background-color: var(--c-white);
    color: var(--c-black);
  }
}

.image-container {
  width: 100%;
  height: 100%;
}

.image-container picture {
  width: 100%;
  height: 100%;
  display: flex;
}

.image-container img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.image-container--front-banner {
  width: min(100% - 30px, 1140px);
}

.cards-widget {
  grid-template: auto / repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
  width: min(100%, 1100px);
  display: grid;
}

.card-item {
  grid-template-rows: subgrid;
  grid-row: span var(--card-item-sections, 3);
  text-align: center;
  gap: 10px 0;
  padding: 30px 12px 12px;
  font-size: 18px;
  line-height: 1.3;
  display: grid;
}

@media (width >= 1200px) {
  .card-item {
    font-size: 20px;
  }
}

.card-item span.font-weight-bold {
  font-weight: 740;
}

.card-item p {
  margin: 0 0 8px;
}

.card-item .button {
  width: 100%;
  margin-top: 10px;
}

.card-item--top-image {
  --image-size: 280px;
  --image-shift: -200px;
  margin-top: calc(var(--image-shift) * -1);
}

.card-item--top-image img {
  width: var(--image-size);
  height: var(--image-size);
}

.card-item--top-image .card-item-image:first-of-type {
  transform: translateY(var(--image-shift));
  margin: 0 auto var(--image-shift);
  position: relative;
}

@media (hover: hover) and (pointer: fine) {
  .card-item:hover img {
    transform-origin: 50% 100%;
    animation: .76s cubic-bezier(.39, .575, .565, 1) forwards jumping;
  }
}

.card-item--violet {
  box-shadow: 0 0 0 1px inset var(--c-amber);
  background: linear-gradient(22deg, #505050 0%, #000 100%);
}

.card-item--sky-blue {
  background: linear-gradient(-58deg, #01325b 0%, #000 100%);
  box-shadow: inset 0 0 0 2px #00b7ff;
}

.card-item--golden-sand {
  background: linear-gradient(-58deg, #000 0%, #2d1501 100%);
  box-shadow: inset 0 0 0 1px #87573d;
}

@keyframes jumping {
  20% {
    transform: translateY(1%)scaleY(.95);
  }

  40% {
    transform: translateY(-7.5%)scaleY(1.05);
  }

  50% {
    transform: translateY(2.5%)scaleY(.9);
  }

  70% {
    transform: translateY(-2%)scaleY(1);
  }

  80%, 100% {
    transform: translateY(0)scaleY(1);
  }
}

.card-mega {
  color: var(--c-black);
  max-width: 600px;
  margin: auto;
  position: relative;
}

@media (width >= 992px) {
  .card-mega {
    max-width: unset;
    grid-template: 1fr / auto 1fr;
    gap: 0;
    height: 100%;
    display: grid;
  }
}

.card-mega__image {
  z-index: 1;
  width: fit-content;
  margin: auto;
  position: relative;
}

.card-mega__image img {
  width: 280px;
  height: 280px;
}

@media (width >= 992px) {
  .card-mega__image img {
    width: 420px;
    height: 420px;
  }
}

.card-mega__body {
  z-index: 1;
  text-align: center;
  justify-items: center;
  padding: 10px 20px 20px;
  display: grid;
  position: relative;
}

@media (width >= 992px) {
  .card-mega__body {
    padding: calc(var(--v-offset-sm) / 1.5) var(--v-offset-md) var(--v-offset-sm) 0;
  }
}

.card-mega__body span.font-weight-bold {
  font-weight: 740;
}

.card-mega__body p {
  margin: 0 0 10px;
  font-size: 18px;
}

@media (width >= 992px) {
  .card-mega__body p {
    font-size: 22px;
  }
}

.card-mega__body .title-large {
  margin: 0 0 10px;
  font-size: 24px;
}

@media (width >= 992px) {
  .card-mega__body .title-large {
    font-size: 30px;
  }
}

.card-mega__body .button {
  margin: 14px auto auto;
}

.card-mega:after {
  content: "";
  box-shadow: 0 0 0 4px inset var(--c-pale-yellow);
  background-color: #ecc063;
  width: 100%;
  height: calc(100% - 170px);
  margin: auto;
  position: absolute;
  inset: auto 0 0;
}

@media (width >= 992px) {
  .card-mega:after {
    width: 74%;
    height: 100%;
    inset: 0 0 0 auto;
  }
}

.widget-front-banner {
  justify-items: center;
  width: 100%;
  max-width: 100vw;
  display: grid;
  position: relative;
  overflow: hidden;
}

@media (width >= 992px) {
  .widget-front-banner {
    max-width: unset;
    overflow: unset;
  }
}

.widget-front-banner__text {
  z-index: 1;
  justify-items: center;
  width: min(100% - 30px, 1200px);
  display: grid;
  position: relative;
}

@media (width >= 992px) {
  .widget-front-banner__text {
    margin: -100px auto auto;
  }
}

.widget-front-banner .headline-small {
  margin: var(--v-offset-sm) 0 0;
  width: unset;
}

.widget-pragmatic-play {
  padding: calc(var(--v-offset-sm) / 2) 0 var(--v-offset-sm);
  width: min(100% - 30px, 960px);
}

.widget-cards-one {
  width: min(100% - 30px, 1100px);
}

.widget-prize-draw {
  padding: calc(var(--v-offset-sm) / 2) 0 var(--v-offset-sm);
  width: min(100% - 30px, 960px);
  transform: translateY(30px);
}

@media (width >= 1200px) {
  .widget-prize-draw {
    transform: translateY(40px);
  }
}

.widget-cards-two {
  width: min(100% - 30px, 1100px);
}

.widget-cards-two .card-item:first-child {
  box-shadow: 0 0 0 2px inset var(--c-amber);
}

.widget-cards-three {
  width: min(100% - 30px, 1100px);
}

.widget-telegram {
  margin-bottom: var(--v-offset-sm);
  justify-items: center;
  width: min(100% - 30px, 1100px);
  display: grid;
}
/*# sourceMappingURL=index.90295bdb.css.map */
