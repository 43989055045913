:root {
  // z-orders
  --z-index-header: 1;

  // colors
  --c-white: rgb(255 255 255);
  --c-black: rgb(0 0 0);
  --c-golden-sand: rgb(227 180 84);
  --c-pale-yellow: rgb(251 255 164);
  --c-amber: rgb(255 183 0);
  --c-vivid-red: rgb(227 34 69);

  // offsets
  --v-offset-sm: min(50px, 3vmax);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);

  @media (width >= 992px) {
    --v-offset-sm: min(40px, 2vmax);
    --v-offset-md: min(80px, 4vmax);
  }

  // NPM package typographics
  --max-vw: 1600;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .6;
  --font-family-base: "Merriweather", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;

  // scrollbar colors
  --c-scrollbar-track: var(--c-black);
  --c-scrollbar-thumb: var(--c-vivid-red);
  --c-scrollbar-hover: var(--c-vivid-red);
}
