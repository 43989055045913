.display-small {
  max-inline-size: none;
  font-size: 5em;
  font-weight: 700;
  text-align: center;
  margin: var(--v-offset-sm) 0;
  width: unset;
  color: var(--c-amber);
}

.headline-large {
  width: min(100% - 30px, 960px);
  max-inline-size: none;
  font-size: 3.8em;
  font-weight: 700;
  text-align: center;
  color: var(--c-amber);
}

.headline-medium {
  width: min(100% - 30px, 960px);
  max-inline-size: none;
  font-size: 4.8em;
  font-weight: 700;
  text-align: center;
  color: var(--c-amber);
}

.headline-small {
  max-inline-size: none;
  font-size: 2.5em;
  font-weight: 300;
  text-align: center;
  line-height: 1.35;
}

.title-large {
  font-size: 3em;
  font-weight: 600;
}
